/**
 * Filtrar dados de arrays
 */
import Vue from "vue";
Vue.filter("filterEstadosCivis", function(value, obj) {
  return obj.filter((e) => e.id === value)[0].nome;
});

Vue.filter("filterSeguradoras", function(value, obj) {
  return obj.filter((e) => e.id === value)[0].seguradora_nome;
});
