<template>
  <a-card class="w-100">
    <h6>
      Dados do imóvel
    </h6>
    <a-card class="bg-success" hoverable>
      <table class="table table-sm table-borderless text-white">
        <tr>
          <td class="text-right">
            <small>CEP:</small>
          </td>
          <td class="text-left h6">
            {{ formAuto.cep }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>Rua:</small>
          </td>
          <td class="text-left h6">
            {{ formAuto.bem.logradouro }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>Quadra:</small>
          </td>
          <td class="text-left h6">
            {{ formAuto.bem.quadra }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>N°:</small>
          </td>
          <td class="text-left h6">
            {{ formAuto.bem.numero }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>Bairro:</small>
          </td>
          <td class="text-left h6">
            {{ formAuto.bem.bairro }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>Cidade:</small>
          </td>
          <td class="text-left h6">
            {{ formAuto.bem.localidade }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>Complemento:</small>
          </td>
          <td class="text-left h6">
            {{ formAuto.bem.complemento }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <small>Estado:</small>
          </td>
          <td class="text-left h6">
            {{ formAuto.bem.uf }}
          </td>
        </tr>
      </table>
    </a-card>
    <h6 class="mt-4">
      Os seus dados estão corretos?
    </h6>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Como podemos chamar você</small><br />
        {{ formAuto.nomeCliente }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Como prefere ser atendido(a)</small><br />
        {{
          formAuto.tipoAtendimento === "W"
            ? "Whatsapp(recomendado)"
            : "Telefone, prefiro que me liguem"
        }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Contato</small><br />
        {{ formAuto.celular }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Residência própria ou alugada?</small><br />
        {{ formAuto.tipoResidencia === "P" ? "Própria" : "Alugada" }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Casa ou apartamento?</small><br />
        {{ formAuto.tipoImovel === "C" ? "Casa" : "Apartamento" }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted"
          >Qual o valor estimado de avaliação de sua residência (Prédio +
          Conteúdo)</small
        ><br />
        {{ formAuto.valorEstimado }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Já possui seguro</small><br />
        {{ formAuto.possuiSeguro === 1 ? "Sim" : "Não" }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3" v-if="formAuto.possuiSeguro === 1">
        <small class="text-muted">Qual a seguradora</small><br />
        {{ formAuto.idSeguradora | filterSeguradoras(stateSeguradoras) }}
      </div>
    </div>
    <div class="row" v-if="formAuto.possuiSeguro === 1">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Qual a classe de bônus</small><br />
        {{ formAuto.classeBonus }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Houve sinistro na última vigência</small
        ><br />
        {{ formAuto.houveSinistro === 1 ? "Sim" : "Não" }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <div v-if="formAuto.cpf !== ''">
          <small class="text-muted">CPF:</small><br />
          {{ formAuto.cpf }}
        </div>
        <div v-if="formAuto.cnpj !== ''">
          <small class="text-muted">CNPJ:</small><br />
          {{ formAuto.cnpj }}
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Data de nascimento</small><br />
        {{ formAuto.nascimento }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Estado civil</small><br />
        {{ formAuto.idEstadoCivil | filterEstadosCivis(stateEstadosCivis) }}
      </div>
      <div class="col-sm-12 col-lg-6 mb-3">
        <small class="text-muted">Qual o seu e-mail</small><br />
        <span class="text-lowercase">
          {{ formAuto.email }}
        </span>
      </div>
    </div>
  </a-card>
</template>

<script>
import "@/filters/generic-filters/filter-arrays";
import { mapGetters } from "vuex";
export default {
  props: ["formAuto"],
  computed: {
    ...mapGetters([
      "stateSeguradoras",
      "stateEstadosCivis",
      "stateMarcasCarros",
    ]),
  },
};
</script>
