<template>
  <div>
    <div v-if="this.loading">
      <a-button
        :loading="true"
        :size="this.size"
        :disabled="this.disabled"
        :class="this.classess"
        style="width: 126.42px"
      >
        &nbsp;
      </a-button>
    </div>
    <div v-else>
      <a-button
        :size="this.size"
        :disabled="this.disabled"
        :class="this.classess"
        @click="handleClick"
      >
        {{ this.label }}
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["label", "size", "disabled", "classess", "loading"],
  methods: {
    handleClick() {
      this.$emit("emitter-click");
    },
  },
};
</script>
